import { Protected, RolePage } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Content } from "~root/content";
import { DeploymentPage } from "./deployment/deployment_page";

export const ContentPage: FC = () => {
	return (
		<Routes>
			<Route path="*" element={
				<Protected fallback={<Navigate to="/auth/login" />}>
					<Content />
				</Protected>
			}>
				<Route path="dashboard/*" element={<div>Dashboard</div>} />
				<Route path="deployment/*" element={<DeploymentPage />} />
				<Route path="users-roles/*" element={<UsersRolesPage />} />
			</Route>
		</Routes>
	);
};

const UsersRolesPage = () => {
	return (
		<Routes>
			<Route path="users/*" element={<div>Users</div>} />
			<Route path="roles/*" element={<RolePage />} />
			<Route path="*" element={<Navigate to="users" />} />
		</Routes>
	);
};
