import {
	AutoDataList,
	ContextMenu,
	ContextMenuDeleteItem,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeading,
	IDataListColumn,
	IconButton,
	Id,
	TextControl,
	Trigger,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SystemInfo } from "~root/deployment/licence_info";
import { productDeploymentService } from "~shared/api/product_deployment";
import { productDeploymentKeys } from "~shared/api/product_deployment/product_deployment_keys";
import { IProductDeploymentRequest, IProductDeploymentResource } from "~shared/types/product_deployment";
import { ProductDeploymentForm } from "./product_deployment_form";

export const DeploymentPage: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	const handleDelete = useCallback(
		async (productId: Id) => {
			const response = await productDeploymentService.remove(productId);
			if (apiIsOK(response)) {
				showToast({
					body: t("Deployment was deleted."),
					title: t("Deployment"),
					type: "success",
				});
			}
		},
		[t, showToast]
	);

	const dataListColumns: IDataListColumn<IProductDeploymentResource>[] = useMemo(
		() => [
			{
				heading: t("Id"),
				valueKey: "id",
				type: "value",
			},
			{
				heading: t("Subdomain name"),
				valueKey: "subdomainName",
				type: "value",
			},
			{
				heading: t("Domain"),
				valueKey: "domain",
				type: "value",
				render: (productDeploymentResource) => productDeploymentResource.domain.name,
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: t("Deployment type"),
				valueKey: "deploymentType",
				type: "value",
			},
			{
				heading: t("Server"),
				valueKey: "server",
				type: "value",
				render: (productDeploymentResource) => productDeploymentResource.server.name,
			},
			{
				heading: t("System info"),
				valueKey: "systemInfo" as any,
				type: "value",
				render: (productDeploymentResource) => (
					<SystemInfo
						productId={productDeploymentResource.id}
						numberOfParticipants={productDeploymentResource.licence.numberOfParticipants}
					/>
				),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (productDeploymentResource) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuDeleteItem
							action={() => handleDelete(productDeploymentResource.id)}
							title={t("Delete")}
							label={t("Delete")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this deployment?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[t, handleDelete]
	);

	return (
		<AutoDataList
			heading={t("Deployments")}
			empty={t("No deployments yet")}
			headerActions={
				<Trigger render={(props) => <ProductDeploymentDrawer {...props} />}>
					{(props) => <IconButton {...props} title={t("Add")} color="primary" size="small" icon="plus" />}
				</Trigger>
			}
			columns={dataListColumns}
			queryKey={productDeploymentKeys.index}
			fetcher={productDeploymentService.index}
		/>
	);
};

export const ProductDeploymentDrawer: FC<{ open: boolean; handleClose: () => void }> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const [bashScriptParams, setBashScriptParams] = useState<string>("");
	const onSubmit = useCallback(async (productDeployment: IProductDeploymentRequest) => {
		const response = await productDeploymentService.post(productDeployment);

		setBashScriptParams(response.data.data.bashScriptParams);
	}, []);

	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{t("Product deployment")}</DrawerHeading>
			</DrawerHeader>
			<DrawerBody>
				{bashScriptParams ? (
					<TextControl
						value={bashScriptParams}
						name={"bashScriptParams"}
						label={t("Bash script params")}
						autoFocus={true}
					/>
				) : (
					<ProductDeploymentForm onSubmit={onSubmit} />
				)}
			</DrawerBody>
		</Drawer>
	);
};
