import { SidebarLayout } from "@dgs/core";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { ContentProviders } from "~root/content_providers";
import { Header } from "~root/header";
import { Sidebar } from "~root/sidebar";

const ContentContainer = styled.div`
	position: relative;
	flex: 1;
	overflow: auto;
	height: 100%;
	padding: 1.5rem;
`;

export const Content: FC = () => {
	return (
		<ContentProviders>
			<SidebarLayout
				header={<Header />}
				sideBar={<Sidebar />}
				content={
					<ContentContainer>
						<Outlet />
					</ContentContainer>
				}
			/>
		</ContentProviders>
	);
};
