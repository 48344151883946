import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { serverKeys, serverService } from "~shared/api/server";

interface Props {
	name: string;
	label: string;
}

const fetcher = (search: string, page: number) =>
	serverService.options({ params: { search, page } }).then((r) => r.data);

export const ServerSingleSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={serverKeys.options} required={true} />;
};
