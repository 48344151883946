import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { domainKeys, domainService } from "~shared/api/domain";

interface Props {
	name: string;
	label: string;
}

const fetcher = (search: string, page: number) =>
	domainService.options({ params: { search, page } }).then((r) => r.data);

export const DomainSingleSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={domainKeys.options} required={true} />;
};
