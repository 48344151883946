import { useQuery } from "@tanstack/react-query";
import { productDataKeys } from "./product_data_keys";
import { productDataService } from "./product_data_service";

const productDataLocalesFetcher = () => productDataService.locales().then((r) => r.data.data);
export const useProductDataLocalesQuery = () => {
	const { data, isLoading } = useQuery({ queryKey: productDataKeys.locales(), queryFn: productDataLocalesFetcher });

	return {
		data,
		isLoading,
	};
};
