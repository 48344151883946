import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { licenceKeys, licenceService } from "~shared/api/licence";

interface Props {
	name: string;
	label: string;
}

const fetcher = (search: string, page: number) =>
	licenceService.options({ params: { search, page } }).then((r) => r.data);

export const LicenceSingleSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={licenceKeys.options} required={true} />;
};
