import { FCC, LocaleProvider } from "@dgs/core";
import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "~root/i18n";

interface Props {
    locale?: string;
    timeZone?: string;
}

const changeLanguage = (language?: string) => i18n.changeLanguage(language);

export const AMLocaleProvider: FCC<Props> = ({
    children,
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale = Intl.DateTimeFormat().resolvedOptions().locale,
}) => {
    return (
        <I18nextProvider i18n={i18n}>
            <LocaleProvider initialLocale={locale} initialTimeZone={timeZone} onChange={changeLanguage}>
                {children}
            </LocaleProvider>
        </I18nextProvider>
    );
};
