import {
	AxiosConfig,
	FCC,
	UserPermissionProvider,
	useDynamicConfig,
} from "@dgs/core";
import React from "react";
import { PermissionName } from "~shared/types";
import { ContentLocaleProvider } from "~shared/ui/content_locale";

export const ContentProviders: FCC = ({ children }) => {
	const { apiUrl } = useDynamicConfig();

	return (
		<UserPermissionProvider<PermissionName>>
			<AxiosConfig apiUrl={apiUrl} />
			<ContentLocaleProvider>{(initialized) => initialized && children}</ContentLocaleProvider>
		</UserPermissionProvider>
	);
};
