import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ProductDataLocales } from "~shared/types";
import { productDataKeys } from "./product_data_keys";

export const productDataUrl = {
	index: keyToUrl(productDataKeys.index),
	locales: () => keyToUrl(productDataKeys.locales()),
};


const locales = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<ProductDataLocales>>(productDataUrl.locales(), config);

export const productDataService = {
	locales,
};
