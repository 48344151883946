import { NotAuthorizedPage, Protected } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppProviders } from "~root/app_providers";
import { AMAuthPages } from "~root/auth";
import { ContentPage } from "~root/content_page";

export const App: FC = () => {
	return (
		<AppProviders>
			<Routes>
				<Route path="/auth/*" element={<AMAuthPages />} />
				<Route
					path="/*"
					element={
						<Protected fallback={<Navigate to="/auth/login" />}>
							<ContentPage />
						</Protected>
					}
				/>
				<Route path="not-authorized" element={<NotAuthorizedPage title="ASSET MANAGEMENT" redirect={-1} />} />
			</Routes>
		</AppProviders>
	);
};
