import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IDomainOptionResource } from "~shared/types/product_deployment";
import { domainKeys } from "./domain_keys";

export const domainUrl = {
	options: keyToUrl(domainKeys.options),
};

const options = (config?: AxiosRequestConfig) => axios.get<LaravelResponse<IDomainOptionResource[]>>(domainUrl.options, config);

export const domainService = {
	options,
};
