import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IServerOptionResource } from "~shared/types/product_deployment";
import { serverKeys } from "./server_keys";

export const serverUrl = {
	options: keyToUrl(serverKeys.options),
};

const options = (config?: AxiosRequestConfig) => axios.get<LaravelResponse<IServerOptionResource[]>>(serverUrl.options, config);

export const serverService = {
	options,
};
