import {Id, LaravelResponse, ListFetcherConfig, keyToUrl} from "@dgs/core";
import axios from "axios";
import {
	IProductDeploymentCreatedResource,
	IProductDeploymentRequest,
	IProductDeploymentResource,
} from "~shared/types/product_deployment";
import { productDeploymentKeys } from "./product_deployment_keys";

export const productDeploymentUrl = {
	index: keyToUrl(productDeploymentKeys.index),
	delete: (productId: Id) => keyToUrl(productDeploymentKeys.details(productId)),
};

const index = ({ pageParams, sortParams, searchParams }: ListFetcherConfig) =>
	axios.get<LaravelResponse<IProductDeploymentResource[]>>(productDeploymentUrl.index, {
		params: { ...pageParams, ...sortParams, ...searchParams },
	});
const post = (productDeployment: IProductDeploymentRequest) =>
	axios.post<LaravelResponse<IProductDeploymentCreatedResource>>(productDeploymentUrl.index, productDeployment);

const remove = (productId: Id) =>
	axios.delete<LaravelResponse<IProductDeploymentCreatedResource>>(productDeploymentUrl.delete(productId));

export const productDeploymentService = {
	index,
	post,
	remove,
};
