import { CookieRefreshService, FCC, JarvisCookieAuthProvider, useDynamicConfig } from "@dgs/core";
import React from "react";

export const AMAuthProvider: FCC = ({ children }) => {
	const { authUrl } = useDynamicConfig();

	return (
		<JarvisCookieAuthProvider authUrl={authUrl}>
			<CookieRefreshService />
			{children}
		</JarvisCookieAuthProvider>
	);
};
