import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IProductLicenceCheckResource } from "~shared/types/product";

export const productKeys = {
	licenceCheck: (productId: Id) => ["products", `${productId}`, "licence-check"],
};

export const productUrl = {
	licenceCheck: (productId: Id) => keyToUrl(productKeys.licenceCheck(productId)),
};

const licenceCheck = (productId: Id) =>
	axios.get<LaravelResponse<IProductLicenceCheckResource>>(productUrl.licenceCheck(productId));

export const productService = {
	licenceCheck,
};
