import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ILicenceOptionResource } from "~shared/types/product_deployment";
import { licenceKeys } from "./licence_keys";

export const licenceUrl = {
	options: keyToUrl(licenceKeys.options),
};

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<ILicenceOptionResource[]>>(licenceUrl.options, config);

export const licenceService = {
	options,
};
