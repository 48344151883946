import {
	DynamicConfigProvider,
	FCC,
	ReactComponentsThemeProvider,
	ToastList,
	ToastProvider,
	getDefaultTheme,
} from "@dgs/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AMAuthProvider } from "~root/auth";
import { config } from "~root/config";
import { AMLocaleProvider } from "~shared/am_locale_provider";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: 100000, retry: false },
	},
});

const theme = getDefaultTheme(config.mode);

export const AppProviders: FCC = ({ children }) => {
	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<DynamicConfigProvider>
					<AMAuthProvider>
						<AMLocaleProvider>
							<ReactComponentsThemeProvider theme={theme}>
								<ToastProvider>
									<ToastList />
									{children}
								</ToastProvider>
							</ReactComponentsThemeProvider>
						</AMLocaleProvider>
					</AMAuthProvider>
				</DynamicConfigProvider>
			</QueryClientProvider>
		</BrowserRouter>
	);
};
