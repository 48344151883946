import { SingleSelectField, SubmitButton, TextField } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IProductDeploymentFormState, IProductDeploymentRequest, TProductType } from "~shared/types/product_deployment";
import { CustomerSingleSelectField } from "~shared/ui/customer_single_select_field";
import { DomainSingleSelectField } from "~shared/ui/domain_single_select_field";
import { LicenceSingleSelectField } from "~shared/ui/licence_single_select_field";
import { ServerSingleSelectField } from "~shared/ui/server_single_select_field";

const productTypeOptions: TProductType[] = ["DEEP", "KRONOS", "PARTICIPANT_MANAGEMENT", "DIONYSOS_FULL"];
const emptyProductDeployment: IProductDeploymentFormState = {
	subdomainName: "",
	type: "PARTICIPANT_MANAGEMENT",
	server: null,
	domain: null,
	licence: null,
	customer: null,
};
interface Props {
	onSubmit: (productDeployment: IProductDeploymentRequest) => Promise<void>;
}
export const ProductDeploymentForm: FC<Props> = ({ onSubmit }) => {
	const { t } = useTranslation();
	const handleSubmit = useCallback(
		({ domain, server, licence, customer, ...values }: IProductDeploymentFormState) => {
			if (!server || !domain || !licence || !customer) return;

			return onSubmit({
				subdomainName: values.subdomainName,
				type: values.type,
				domainId: domain.id,
				serverId: server.id,
				licenceId: licence.id,
				customerId: customer.id,
			});
		},
		[onSubmit]
	);
	const validate = useCallback(
		(values: IProductDeploymentFormState) => {
			const errors: { [K in keyof IProductDeploymentFormState]?: any } = {};

			if (!values.subdomainName) {
				errors.subdomainName = t("Required field");
			}
			if (!values.type) {
				errors.type = t("Required field");
			}
			if (!values.customer) {
				errors.customer = t("Required field");
			}
			if (!values.domain) {
				errors.domain = t("Required field");
			}
			if (!values.server) {
				errors.server = t("Required field");
			}
			if (!values.licence) {
				errors.licence = t("Required field");
			}
			return errors;
		},
		[t]
	);

	return (
		<Formik initialValues={emptyProductDeployment} validate={validate} onSubmit={handleSubmit}>
			<Form>
				<TextField name="subdomainName" label={t("Subdomain name")} required={true} />
				<SingleSelectField
					options={productTypeOptions}
					getLabel={(x) => x}
					name="type"
					label={t("Product type")}
					required={true}
				/>
				<CustomerSingleSelectField name="customer" label={t("Customer")} />
				<LicenceSingleSelectField name="licence" label={t("Licence")} />
				<DomainSingleSelectField name="domain" label={t("Domain")} />
				<ServerSingleSelectField name="server" label={t("Server")} />
				<SubmitButton>{t("Submit")}</SubmitButton>
			</Form>
		</Formik>
	);
};
