import { AutoSidebar, SidebarItem, useUserPermission } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PermissionName } from "~shared/types";
import { SidebarHeader } from "./sidebar_header";

const StyledAutoSidebar = styled(AutoSidebar)`
	position: relative;
	z-index: ${({ theme }) => theme.zIndices.content.l2};
`;

export const Sidebar: FC = () => {
	const { t } = useTranslation();
	const { permissions } = useUserPermission();

	const sidebarItems = useMemo<SidebarItem<PermissionName>[]>(
		() => [
			{
				icon: "menu",
				key: t("Dashboard"),
				permissions: ["dashboard.read"],
				to: "/dashboard",
			},
			{
				icon: "menu",
				key: t("Deployment"),
				permissions: ["user.read"],
				to: "/deployment",
			},
			{
				icon: "people",
				key: t("Users & Roles"),
				permissions: ["user.read", "role.read"],
				to: "/users-roles",
				children: [
					{
						icon: "person",
						key: t("Users"),
						permissions: ["user.read"],
						to: "/users-roles/users",
					},
					{
						icon: "lock_open",
						key: t("Roles"),
						permissions: ["role.read"],
						to: "/users-roles/roles",
					},
				],
			},
		],
		[t]
	);

	return <StyledAutoSidebar header={<SidebarHeader />} sidebarItems={sidebarItems} permissions={permissions} />;
};
