import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ICustomerOptionResource } from "~shared/types/product_deployment";
import { customerKeys } from "./customer_keys";

export const customerUrl = {
	options: keyToUrl(customerKeys.options),
};

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<ICustomerOptionResource[]>>(customerUrl.options, config);

export const customerService = {
	options,
};
